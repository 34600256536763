// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogi-js": () => import("./../../../src/pages/blogi.js" /* webpackChunkName: "component---src-pages-blogi-js" */),
  "component---src-pages-ilmoittaudu-kurssille-js": () => import("./../../../src/pages/ilmoittaudu-kurssille.js" /* webpackChunkName: "component---src-pages-ilmoittaudu-kurssille-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kiitos-js": () => import("./../../../src/pages/kiitos.js" /* webpackChunkName: "component---src-pages-kiitos-js" */),
  "component---src-templates-basic-page-js": () => import("./../../../src/templates/basic-page.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-course-page-js": () => import("./../../../src/templates/course-page.js" /* webpackChunkName: "component---src-templates-course-page-js" */)
}

